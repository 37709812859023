import { Stack } from '@mui/material'
import React from 'react'

const InvoiceHeader = ({children}) => {

  return (
    <>

        <div className="invoicer">


                <Stack spacing={2}>
                  
                    <div className="invoicer-header">
                        <h1 className="invoicer-title">Ibn Haysam Biggan Madrasah</h1>
                        <h2 className="invoicer-title">Invoice</h2>
                    </div>

                    {children}

                </Stack>              

        </div>
    </>
  )
}

export default InvoiceHeader
