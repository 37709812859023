import { Button, Card, CardContent, Stack } from '@mui/material'
import React, { useState } from 'react'
import dayjs from 'dayjs'
import { DateField, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import DailyClassBasedTrx from './DailyClassBasedTrx'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify'

// const url = `https://api.ibnhaysam.com/api/v1/studentFinancial/daily-transactions`

const DailyClassReport = () => {

    const [start, selectStartDate] = useState(null)
    const [end, selectEndDate] = useState(null)
    const [show, setShow] = useState(false)

    const formik = useFormik({
        initialValues: {
            start: null,
            end: null
        },
        validationSchema: Yup.object().shape({
            start: Yup.string().required('Start Date requires'),
            end: Yup.string().required("End Date requires")
        }),
        onSubmit: async ( values ) => {
            try{
                console.log(values)
                setShow(true)
            }catch(error){
                console.error(error)
                toast.error(error.message)
            }
        }
    })

  return (
    <>

        {
            <ToastContainer />
        }

        <Stack spacing={2}>

            <div className="row">
                <div className="col-xs-12 col-sm-8">
                    <Card>
                        <CardContent>

                            <form onSubmit={formik.handleSubmit}>

                                <Stack spacing={2}>

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            
                                            <DateField 
                                                name="start" 
                                                label="Select Start Date" 
                                                onChange={(newValue) => {
                                                    const formattedDate = dayjs(newValue).format('YYYY-MM-DD');
                                                    formik.setFieldValue( 'start', formattedDate);
                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            
                                            <DateField 
                                                name="end" 
                                                label="Select End Date" 
                                                onChange={(newValue) => {
                                                    const formattedDate = dayjs(newValue).format('YYYY-MM-DD');
                                                    formik.setFieldValue( 'end', formattedDate);
                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                    
                                    <div>
                                        <Button type="submit" variant='contained'>Submit</Button>
                                    </div>

                                </Stack>


                            </form>


                        </CardContent>
                    </Card>

                    
                </div>
            </div>

            {
                show && <DailyClassBasedTrx start={formik.values.start} end={formik.values.end} />
            }

        </Stack>

      
    </>
  )
}

export default DailyClassReport
