import React from 'react'
import useApi from '../hooks/useApi'
import dayjs from 'dayjs'
import { DataGrid, GridToolbar} from '@mui/x-data-grid'

//http://localhost:2020
//https://api.ibnhaysam.com
const url = 'http://localhost:2020/api/v1/financial/get-salary-history'

const PaymentHistory = ({id}) => {

    const {data, isLoading, error } = useApi(`${url}/${id}`)

    const columns = [
        { field: 'grossSalary', headerName: 'Salary', flex: 1 },
        {
            field: 'month',
            headerName: "Month",
            flex: 1,
            renderCell: params => {
                console.log(params.row.month)
                return dayjs().month(params.row.month - 1).format('MMM')
            }
        },
        {
            field: 'year',
            headerName: 'Year',
            flex: 1,
        },
        { 
            field: 'paidAt', 
            headerName: 'Date', 
            flex: 1, 
            renderCell: params => {
                return dayjs(params.row.paidAt).format("MM/DD/YYYY")
            }
        },
    ]

  return (
    <>
      {
        data && data.length > 0 && !isLoading && !error &&
        <DataGrid 
            columns={columns}
            rows={data}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 10,
                    },
                },
            }}
            slots={{
                toolbar: GridToolbar
            }}
            slotProps={{
                toolbar: {
                    csvOptions: { fileName: `Salary History ${id}` },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                }
            }}
            pageSizeOptions={[10]}
            
            disableRowSelectionOnClick
            disableColumnMenu
            getRowId={(row) => row._id} // Use _id as the unique identifier

        />
      }
    </>
  )
}

export default PaymentHistory
