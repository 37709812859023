import { Backdrop, Button, Card, CardContent, CircularProgress, List, ListItem, Stack, TextField } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import useApi from '../../hooks/useApi'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import { ToastContainer, toast } from 'react-toastify'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

const url = 'https://api.ibnhaysam.com/api/v1/students/get-student'
const distributionUrl = 'https://api.ibnhaysam.com/api/v1/studentFinancial/student-feeDistributionByYearClass/2024'
const collectionUrl = 'https://api.ibnhaysam.com/api/v1/studentFinancial/get-unique-students-financial'

const ProfileSingle = () => {

    const {id} = useParams()
    const { data, isLoading, error } = useApi(`${url}/${id}`)

    const [isDataLoaded, setIsDataLoaded] = useState(false);

     // useEffect hook to set isDataLoaded to true when data is loaded
     useEffect(() => {
        // Check if data is available
        if (data) {
            setIsDataLoaded(true);
        }
    }, [data]); // Dependency on data variable

    // Fetch fee data only when data is loaded
    const { data: feeData, isLoading: isFeeLoading, error: feeError } = useApi(
        `${isDataLoaded ? `${distributionUrl}/${data?.classLevel?._id}` : ''}`,
        {
            enabled: isDataLoaded // Only enable the hook when isDataLoaded is true
        }
    );

    const {data:collections, isLoading: isCollection, error: collectionError} = useApi(`${collectionUrl}/${id}`)
    console.log("collection: ", collections)

    const authHeader = useAuthHeader()

    const formik = useFormik({
        initialValues: {
            discount: 0
        },
        validationSchema: Yup.object().shape({
            discount: Yup.number().min(0).max(100)
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                // If validation fails, stop submission
                if (!values.discount) {
                    setSubmitting(false);
                    return;
                }
    
                // Ensure that discount value is a positive number and within the range of 0 to 100
                if (isNaN(values.discount) || values.discount < 0 || values.discount > 100) {
                    setSubmitting(false);
                    toast.error('Discount must be a positive number between 0 and 100');
                    return;
                }
    
                const url = 'https://api.ibnhaysam.com/api/v1/students/update-student-discount';
                const headers = {
                    "Authorization": authHeader,
                    'Content-Type': 'application/json', // Changed content type
                };
    
                const response = await axios.put(`${url}/${id}`, values, { headers: headers });
                toast.success(response.data.message);
                console.log("Response: ", response);
            } catch (error) {
                console.error(error);
                toast.error(error.message);
            } finally {
                setSubmitting(false);
            }
        }
    })

    useEffect(()=>{
        formik.setValues({
            discount: data?.discount
        })
    }, [data])

    const navigation = useNavigate()

    const handleBack = ()=>{
        navigation(-1)
    }

    const columns = [
        { 
            field: 'tnxId', 
            headerName: "Trx Id", 
            flex: 1,
        },
        { 
            field: 'totalAmount', 
            headerName: 'Amount', 
            flex: 1
        },
        { 
            field: 'paidAt', 
            headerName: 'Date', 
            flex: 1,
            renderCell: params => {
                return dayjs(params.row?.paidAt)?.format("DD-MMM-YYYY")
            }
        },
    ]

  return (
    <>

        <Backdrop open={isLoading}><CircularProgress /></Backdrop>
        <ToastContainer />
      <div className="row">
        <div className="col-xs-12 col-sm-4">
            <Stack spacing={2}>
                <Card>
                    <CardContent>
                        <Stack spacing={2}>
                            <div>

                                <Button variant='outlined' onClick={handleBack}>
                                    Go Back
                                </Button>
                                
                            </div>
                            <div className="single-student-financial-profile row">
                                <div className="col-xs-12 col-sm-4">
                                    <div className="profile"></div>
                                </div>
                                <div className="col-xs-12 col-sm-8">
                                    <Stack spacing={1}>
                                        <h2>{data?.name}</h2>
                                        <p>{data?.studentId}</p>
                                        <p>{data?.classLevel?.name}</p>
                                        <p>{data?.section?.name}</p>
                                        <p>0{data?.phone}</p>
                                    </Stack>
                                </div>
                            </div>

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{textAlign: 'left'}}>Advance</th>
                                        <th style={{textAlign: 'left'}}>Due</th>
                                        <th style={{textAlign: 'left'}}>Paid</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{collections && collections?.length > 0 && collections[collections?.length - 1]?.advancedPayment}</td>
                                    <td>{collections && collections?.length > 0 && collections[collections?.length - 1]?.totalDue}</td>
                                    <td>{collections && collections?.length > 0 && collections[collections?.length - 1]?.totalPaidAmount}</td>
                                </tr>

                                </tbody>
                            </table>

                        </Stack>
                    </CardContent>
                </Card>

                <Card>
                    <CardContent>
                        <List>
                            <ListItem>
                                
                                    <div className="col-xs-12 col-sm-6">
                                        <h3>Registration Fee</h3>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">{feeData?.registrationFee}</div>
                                
                            </ListItem>
                            <ListItem>
                                    <div className="col-xs-12 col-sm-6">
                                        <h3>Monthly Fee</h3>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">{feeData?.monthlyTuitionFee}</div>
                            </ListItem>
                        </List>
                        
                    </CardContent>
                </Card>

                <Card>
                    <CardContent>

                        <form onSubmit={formik.handleSubmit}>
                            <Stack spacing={2}>
                                <TextField 
                                    name="discount"
                                    label="Discount"
                                    value={formik.values.discount}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <Button type="submit" variant='outlined'>
                                    Update
                                </Button>
                            </Stack>
                        </form>
                        
                    </CardContent>
                </Card>

            </Stack>
        </div>
        <div className="col-xs-12 col-sm-8">
            <Card>
                <CardContent>
                    {
                        !isCollection && collections && collections.length > 0 &&
                            <DataGrid 
                                autoHeight
                                columns={columns}
                                rows={collections}
                                getRowId={row => row && row._id}
                                initialState={{
                                    ...collections?.initialState,
                                    pagination: { paginationModel: { pageSize: 10 } },
                                }}
                                pageSizeOptions={[10, 25, 50, 100]}
                                slots={{
                                    toolbar: GridToolbar
                                }}
                                slotProps={{
                                    toolbar: {
                                        csvOptions: { fileName: "Student Finance" },
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    }
                                }}
                            />
                    }
                </CardContent>
            </Card>
        </div>
      </div>
    </>
  )
}

export default ProfileSingle
