import React from 'react'
import { useParams } from 'react-router-dom'
import useApi from '../../hooks/useApi'
import { Backdrop, Card, CardContent, CircularProgress, Stack } from '@mui/material'
import dayjs from 'dayjs'
import Print from '../../components/Print'
import { toWords } from 'number-to-words';

const url = 'https://api.ibnhaysam.com/api/v1/studentFinancial/student-feeCollectionById'

function ViewSingleTransaction() {

  const {id} = useParams()

  const {data, isLoading, error} = useApi(`${url}/${id}`)

  console.log(data)

  let totalAmountInWords;

  // Ensure totalAmount is defined and is a valid number
  if (data?.totalAmount !== undefined && !isNaN(data.totalAmount)) {
    totalAmountInWords = toWords(data.totalAmount);
  }

  return (
    <>

      {
        <Backdrop open={isLoading}><CircularProgress /></Backdrop>
      }

      <Print>

        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <Card>
                <CardContent>
                    <div className="invoicer">

                        <Stack spacing={2}>

                            {/* invoicer header starts */}
                            <div className="invoicer-header">
                                <h1 className="invoicer-title">Ibn Haysam Biggan Madrasah</h1>
                                <h2 className="invoicer-title">Invoice</h2>
                                <p>Office Copy</p>
                            </div>

                            {/* invoicer pre table starts */}
                            <div className="invoicer-pre-table">
                                  <div className="row">
                                    <div className="col-xs-12 col-sm-6">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-6">
                                                Name:
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                              {data?.student?.name}
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                Student Id:
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                { data?.student?.studentId }
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                Phone:
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                { data?.student?.phone }
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                Class:
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                { data?.student?.classLevel?.name }
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                Section:
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                { data?.student?.section?.name }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">

                                        {/* invoicer infos starts */}
                                        <div className="invoicer-infos">
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-xs-12 col-sm-6">
                                                            Invoice No:
                                                        </div>
                                                        <div className="col-xs-12 col-sm-6">
                                                        {data?.tnxId}
                                                        </div>
                                                        <div className="col-xs-12 col-sm-6">
                                                            Invoice Date:
                                                        </div>
                                                        <div className="col-xs-12 col-sm-6">
                                                            {dayjs(data?.paidAt).format("D-MMM-YYYY")}
                                                        </div>
                                                        <div className="col-xs-12 col-sm-6">
                                                            Due Date:
                                                        </div>
                                                        <div className="col-xs-12 col-sm-6">
                                                            { data?.month } - { data?.year }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-sm-4"></div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* invoicer table starts */}
                            <div className="invoicer-table">

                                <Stack spacing={2}>

                                  <table className="table">
                                      <thead>
                                          <tr>
                                              <th>Description</th>
                                              <th>Amount</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          data?.typeOfFees.map((fee, idx) => (
                                            <tr key={idx}>
                                                <td><h4>{fee.title}</h4></td>
                                                <td><h4>{fee.paidAmount}</h4></td>
                                            </tr>
                                          ))
                                        }
                                        <tr>
                                            <td><p>Paid: </p></td>
                                            <td>{data?.totalAmount}</td>
                                        </tr>
                                        <tr>
                                            <td><p>Payable: </p></td>
                                            <td>{data?.totalAmount + data?.totalDue}</td>
                                        </tr>
                                        <tr>
                                            <td><p>Total Due: </p></td>
                                            <td>{data?.totalDue }</td>
                                        </tr>
                                        <tr>
                                            <td><p>Advance: </p></td>
                                            <td>{data?.advancedPayment }</td>
                                        </tr>
                                        <tr>
                                            <td><p>In Words: </p></td>
                                            <td>{totalAmountInWords }</td>
                                        </tr>
                                      </tbody>
                                  </table>

                                </Stack>

                            </div>

                            <div className="footer">
                                <p>Received by: {data?.createdBy['name']}</p>
                            </div>

                        </Stack>


                    </div>
                </CardContent>
            </Card>
          </div>
          <div className="col-xs-12 col-sm-6">
            <Card>
                <CardContent>
                    <div className="invoicer">

                        <Stack spacing={2}>

                            {/* invoicer header starts */}
                            <div className="invoicer-header">
                                <h1 className="invoicer-title">Ibn Haysam Biggan Madrasah</h1>
                                <h2 className="invoicer-title">Invoice</h2>
                                <p>Student Copy</p>
                            </div>

                            {/* invoicer pre table starts */}
                            <div className="invoicer-pre-table">
                                  <div className="row">
                                    <div className="col-xs-12 col-sm-6">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-6">
                                                Name:
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                              {data?.student?.name}
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                Student Id:
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                { data?.student?.studentId }
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                Phone:
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                { data?.student?.phone }
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                Class:
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                { data?.student?.classLevel?.name }
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                Section:
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                { data?.student?.section?.name }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">

                                        {/* invoicer infos starts */}
                                        <div className="invoicer-infos">
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-xs-12 col-sm-6">
                                                            Invoice No:
                                                        </div>
                                                        <div className="col-xs-12 col-sm-6">
                                                        {data?.tnxId}
                                                        </div>
                                                        <div className="col-xs-12 col-sm-6">
                                                            Invoice Date:
                                                        </div>
                                                        <div className="col-xs-12 col-sm-6">
                                                            {dayjs(data?.paidAt).format("D-MMM-YYYY")}
                                                        </div>
                                                        <div className="col-xs-12 col-sm-6">
                                                            Due Date:
                                                        </div>
                                                        <div className="col-xs-12 col-sm-6">
                                                            { data?.month } - { data?.year }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-sm-4"></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* invoicer table starts */}
                            <div className="invoicer-table">

                                <Stack spacing={2}>

                                  <table className="table">
                                      <thead>
                                          <tr>
                                              <th>Description</th>
                                              <th>Amount</th>
                                          </tr>
                                      </thead>
                                      <tbody>

                                        {
                                          data?.typeOfFees.map((fee, idx) => (
                                            <tr key={idx}>
                                                <td><h4>{fee.title}</h4></td>
                                                <td><h4>{fee.paidAmount}</h4></td>
                                            </tr>
                                          ))
                                        }

                                        <tr>
                                            <td><p>Paid: </p></td>
                                            <td>{data?.totalAmount}</td>
                                        </tr>
                                        <tr>
                                            <td><p>Payable: </p></td>
                                            <td>{data?.totalAmount + data?.totalDue}</td>
                                        </tr>
                                        <tr>
                                            <td><p>Total Due: </p></td>
                                            <td>{data?.totalDue }</td>
                                        </tr>
                                        <tr>
                                            <td><p>Advance: </p></td>
                                            <td>{data?.advancedPayment }</td>
                                        </tr>
                                        <tr>
                                            <td><p>In Words: </p></td>
                                            <td>{totalAmountInWords }</td>
                                        </tr>

                                      </tbody>
                                  </table>

                                </Stack>

                            </div>

                            <div className="footer">
                                <p>Received by: {data?.createdBy['name']}</p>
                            </div>

                        </Stack>


                    </div>
                </CardContent>
              </Card>
          </div>
        </div>
        
      </Print>

    </>
  )
}

export default ViewSingleTransaction
