import React from 'react'
import useGetClasses from '../hooks/useGetClasses'
import { Backdrop, CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';


const SelectClass = ({value, onChange, touched, errors}) => {

    const {classLists, isLoading, error} = useGetClasses();

  return (
    <>

        {
            <Backdrop open={isLoading}><CircularProgress /></Backdrop>
        }

        {
            !isLoading && classLists &&
            <FormControl fullWidth>
                <InputLabel id="class-select">Class</InputLabel>
                <Select
                    labelId="class-select"
                    id="class-select"
                    name='classLevel'
                    label="Class"
                    value={value}
                    onChange={onChange}
                    error={touched && Boolean(errors)}
                >

                    {
                        classLists.map(item => (
                            <MenuItem key={item._id} value={item._id}>
                                {item.name}
                            </MenuItem>
                        ))
                    }

                </Select>
            </FormControl>
        }
      
    </>
  )
}

export default SelectClass
