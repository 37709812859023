import { Backdrop, Button, Card, CardContent, CircularProgress, FormControl, InputLabel, MenuItem, Select, Stack, TextField, useForkRef } from '@mui/material'
import React, { useState } from 'react'
import { Header } from '../../components'
import { useFormik } from 'formik'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import * as Yup from 'yup'
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import ExamSelect from '../../components/examSelect/ExamSelect'
import useGetClasses from '../../hooks/useGetClasses'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

const url = 'https://api.ibnhaysam.com/api/v1/results/get-results-by-section-exam'

function GetSectionBasedResult() {

    const {classLists, isLoading: classLoading, error: classError} = useGetClasses()
    const [loading, setLoading] = useState(false)
    const authHeader = useAuthHeader()
    const [result, setResult] = useState([])

    const columns = [
        { field: 'student', headerName: 'Student Id', flex: 1,
            valueFormatter: (params) => params.row?.student.studentId || '',
            renderCell: params => {
                return params.row.student.studentId
            }
        },
        { field: 'studentName', headerName: 'Student Name', flex: 1,
            valueFormatter: (params) => params.row?.student.name || '',
            renderCell: params => {
                return params.row.student.name
            }
        },
        { field: 'totalMarks', headerName: "Total Marks", flex: 1},
    ]

    const formik = useFormik({
        initialValues: {
          classLevel: '',
          section: '',
          exam: '',
        },
        validationSchema: Yup.object().shape({
          classLevel: Yup.string().required(),
          exam: Yup.string().required(),
          section: Yup.string().required()
        }),
        onSubmit: async (values, {resetForm}) =>{
          try{
    
            setLoading(true)
            const headers = {
              "Authorization": authHeader,
              'Content-Type': 'application/json',
            };

            console.log("Valal " ,values)

            const response = await axios.post( url, values, {headers: headers})

            console.log(response)

            if(response.status === 200){
                setResult(response)
                toast.success(response.message)
            }else{
                toast.error(response.message)
            }
            
            setLoading(false)
    
          }catch(error){
            setLoading(false)
            toast(error.message)
            console.error(error)
          }finally{
            setLoading(false)
          }
        }
      })

  return (
    <>

        {
            <Backdrop open={loading}><CircularProgress /></Backdrop>
        }
        {
            <ToastContainer />
        }



            <Stack spacing={2}>

                <form onSubmit={formik.handleSubmit}>

                    <Card>
                        <CardContent>
                            <Header title="Get Results" noBtn={true} />
                            <div className="row">

                                <div className="form-field col-xs-12 col-sm-6 col-md-4">
                                   
                                    
                                    <FormControl fullWidth>
                                        <InputLabel id="select_class">Select Class</InputLabel>
                                        <Select
                                            name="classLevel"
                                            labelId="select_class"
                                            label="Select Class"
                                            value={formik.values.classLevel}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        >

                                            {
                                                classLists && !classLoading && 
                                                classLists.map( (classList, idx) => {
                                                    return <MenuItem
                                                        key={classList?._id}
                                                        value={classList?._id}
                                                    >
                                                        {classList.name}
                                                    </MenuItem>
                                                })
                                            }

                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="form-field col-xs-12 col-sm-6 col-md-4">

                                    <FormControl fullWidth>
                                        <InputLabel id="select_subject">Select Section</InputLabel>
                                        <Select
                                            name="section"
                                            labelId="select_section"
                                            label="Select Section"
                                            value={formik.values.section}
                                            onChange={formik.handleChange}
                                        >

                                            {
                                                classLists && !classLoading && formik.values.classLevel && classLists.find( classList => classList._id === formik.values.classLevel ).sections?.map( (section, idx) => {
                                                    return <MenuItem
                                                        key={idx}
                                                        value={section._id}
                                                    >
                                                        {section.name} {section.subjectCode}
                                                    </MenuItem>
                                                } )
                                            }

                                        </Select>
                                    </FormControl>
                                    


                                </div>

                                <div className="form-field col-xs-12 col-sm-6 col-md-4">
                                    <ExamSelect 
                                        value={formik.values.exam}
                                        name="exam"
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                
                            </div>

                            <div className="form-field col-12">
                                <Button type="submit" variant='contained'>Submit</Button>
                            </div>
                        </CardContent>
                    </Card>

                </form>

                {
                    result && result.length != 0 &&
                    <Card>
                        <CardContent>

                            <DataGrid 
                                autoHeight
                                rows={result.data.data}
                                columns={columns}
                                getRowId={(row) => row.student._id} 
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                slots={{
                                    toolbar: GridToolbar
                                }}
                                slotProps={{
                                    toolbar: {
                                        csvOptions: { fileName: 'results' },
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    }
                                }}
                                pageSizeOptions={[10, 50, 100]}
                                
                                disableRowSelectionOnClick
                                disableColumnMenu
                            />
                        </CardContent>
                    </Card>
                }

            </Stack>



      
    </>
  )
}

export default GetSectionBasedResult
