import { Card, CardContent } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React from 'react'
import useApi from '../../hooks/useApi'
import { GrEdit, GrView } from 'react-icons/gr'
import { Link } from 'react-router-dom'

const url = 'https://api.ibnhaysam.com/api/v1/studentFinancial/get-unique-students-financial'

const Profiles = () => {

    const {data, isLoading, error} = useApi(url)

    const columns = [
        { 
            field: 'studentId', 
            headerName: "Id", 
            flex: 1, 
            renderCell: params => {
                return params.row.studentId
            }
        },
        { 
            field: 'name', 
            headerName: 'name', 
            flex: 1,
            renderCell: params => {
                return params.row.name
            }
        },
        { 
            field: 'classLevel', 
            headerName: 'Class', 
            flex: 1,
            valueFormatter: (params) => params.value?.name || '',
            renderCell: params => {
                return params.row.classLevel?.name
            }
        },
        { 
            field: 'section', 
            headerName: 'Section', 
            flex: 1,
            valueFormatter: (params) => params.value?.name || '',
            renderCell: params => {
                return params.row.section?.name
            }
        },
        { 
            field: 'discount', 
            headerName: 'Discount', 
            flex: 1,
            renderCell: params => {
                return params.row.discount
            }
        },
    ]

    const actionsColumn = {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        sortable: false,
        renderCell: params => {
            return <>
                <Link to={params.row._id}>
                    <GrView />
                </Link>
                
            </>
        }
    }

  return (
    <>
        <Card>
            <CardContent>
                {
                    !isLoading && data &&
                    <DataGrid 
                        autoHeight
                        columns={[...columns, actionsColumn]}
                        rows={data}
                        getRowId={row => row && row._id}
                        initialState={{
                            ...data.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                        slots={{
                            toolbar: GridToolbar
                        }}
                        slotProps={{
                            toolbar: {
                                csvOptions: { fileName: "Student Finance" },
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            }
                        }}
                    />
                }
            </CardContent>
        </Card>
    </>
  )
}

export default Profiles
