import { Backdrop, Card, CardContent, CircularProgress } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'

const UserLists = () => {

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const fetchData = async () => {
        try {
            setLoading(true)

            const postData = {
                operation: "fetch_user_list",
                auth_user: "ihbm",
                auth_code: "aet7j0bzz0fgu2q3pd4189csm06rhn0",
                access_id:52559874,
            }

            const response = await fetch('https://rumytechnologies.com/rams/json_api', {
                method: 'POST',
                body: JSON.stringify(postData),
            });

            const result = await response.json(); // Make sure to parse the response to JSON
            const data = result.user_list
            console.log("Data: ", data)
            setData(data)

        } catch (err) {
            console.error(err)
            toast.error(err.message || "An error occurred")
            setError(err.message || "An error occurred")
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(); // Call fetchData function

        // Empty dependency array ensures useEffect runs only once when the component mounts
    }, [])

    const columns = [
        {
            field: 'username',
            headerName: "Name",
            width: 300
        },
        {
            field: 'registraton_id',
            headerName: "Registration ID",
            flex: 1,
        }
    ]

    return (
        <div>

            <ToastContainer />
            <Backdrop open={loading}><CircularProgress /></Backdrop>

            <Card>
                <CardContent>

                    {
                        data && data.length > 0 &&

                        <DataGrid 
                            rows={data}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10
                                    }
                                }
                            }}
                            slots={{
                                toolbar: GridToolbar
                            }}
                            slotProps={{
                                toolbar: {
                                    csvOptions: { fileName: "User List" },
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                }
                            }}
                            pageSizeOptions={[10, 50, 100]} 
                            disableRowSelectionOnClick
                            disableColumnMenu
                            getRowId={(row) => row.registraton_id} 
                        />

                    }

                </CardContent>
            </Card>

        </div>
    )
}

export default UserLists
