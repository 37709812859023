import { Backdrop, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import useApi from '../../hooks/useApi'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { GrEdit } from 'react-icons/gr'
import { MdDelete } from 'react-icons/md'
import { Link } from 'react-router-dom'

const url = 'https://api.ibnhaysam.com/api/v1/grade/get-grades'

const Grades = () => {

    const {data, isLoading, error } = useApi(url)
    const [open, setOpen] = useState(false);
    const [studentId, setStudentId] = useState('')

    const columns = [
        {
            field: "minMarks",
            headerName: "Minimum Mark",
            flex: 1
        },
        {
            field: 'maxMarks',
            headerName: "Maximum Mark",
            flex: 1
        },
        {
            field: "grade",
            headerName: "Grade",
            flex: 1
        }
    ]

    const actionsColumn = {
        field: "actions",
        headerName: "Actions",
        width: 100,
        renderCell: (params) => {
            return <div style={{display: 'flex',}}>

                <Link to={`edit/${params.row._id}`}>
                    <GrEdit />
                </Link>
                <div style={{marginLeft: '10px', cursor: 'pointer'}}>
                    <MdDelete onClick={()=> handleClickOpen(params.row._id)}/>
                </div>
                
            </div>;
        },
        sortable: false
    };

    const handleClickOpen = (id) => {
        setOpen(true);
        setStudentId(id)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = ()=>{
        setOpen(false);
    }

    

  return (
    <>
        <Backdrop open={isLoading}> <CircularProgress /> </Backdrop>
        
            <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Do you want to delete the ENTRY?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This process can't be undone later!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        
                        {
                            studentId && 
                                <Button>
                                    <Link to={`delete/${studentId}`}>
                                        Confirm
                                    </Link>
                                </Button>
                                
                            
                        
                        }

                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
            </Dialog>
      <Card>
            <CardContent>

                {
                    !isLoading && data && 
                    <DataGrid 
                        autoHeight
                        columns={[...columns, actionsColumn]}
                        rows={data}
                        slots={{
                            toolbar: GridToolbar
                        }}
                        slotProps={{
                            toolbar: {
                                csvOptions: { fileName: "Student Grade" },
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            }
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                }
                            }
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                        getRowId={row => row && row._id}
                    />
                }

            </CardContent>
      </Card>
    </>
  )
}

export default Grades
