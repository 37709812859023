import React from 'react'

const InvoicerTable = ({headings, children}) => {
  return (
    <>

        <div className="invoicer-table  nested-table">

            <table className="table">
                <thead>
                    <tr>
                        {
                            headings?.map(h=>( <th>{h}</th> ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </table>

        </div>
    </>
  )
}

export default InvoicerTable
