import { Backdrop, Button, Card, CardContent, CircularProgress, Stack, TextField } from '@mui/material'
import axios from 'axios'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import { ToastContainer, toast } from 'react-toastify'
import InvoiceHeader from '../../components/InvoiceHeader'
import InvoicerTable from '../../components/InvoicerTable'
import Print from '../../components/Print'
import InvoicerFooter from '../../components/InvoicerFooter'
import InvoicerBody from '../../components/InvoicerBody'

//https://api.ibnhaysam.com
const url = 'https://api.ibnhaysam.com/api/v1/studentFinancial/get-fee-collection-by-student-id'

const StdentSingleReport = () => {

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [totalDue, setTotalDue] = useState(0)
  const [totalPaidAmount, setTotalPaidAmount] = useState(0)
  const [advancePayment, setAdvancePayment] = useState(0)
  const [student, setStudent ] = useState(null)
  const authHeader = useAuthHeader()

  const formik = useFormik({
    initialValues: {
      studentId: null,
    },
    onSubmit: async (values) => {
      try{

        setLoading(true)

        const headers = {
          "Authorization": authHeader,
        }

        const response = await axios.post(url, values, {headers: headers})
        console.log(response)
        setData(response.data.data)
        setTotalDue(response.data.totalDue)
        setTotalPaidAmount(response.data.totalPaidAmount)
        setAdvancePayment(response.data.advancePayment)
        setStudent(response.data.student)
        toast.success(response.data.message)

      }catch(error){
        setLoading(false)
        console.error(error)
      }finally{
        setLoading(false)
      }
    }
  })
    

  return (
    <>

        {
          <Backdrop open={loading}><CircularProgress /></Backdrop>
        }

        {
          <ToastContainer />
        }

        <div className="row">
            <div className="col-xs-12 col-sm-6">
                <Stack spacing={2}>

                    <Card>
                        <CardContent>
                            <form onSubmit={formik.handleSubmit}>

                                <Stack spacing={2}>

                                    <TextField 
                                        name="studentId"
                                        label="Student Id"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.studentId}
                                        error={formik.errors.studentId && formik.touched.studentId}
                                        helperText={formik.errors.studentId && formik.touched.studentId && formik.errors.studentId}
                                    />

                                    <div>
                                        <Button type="submit" variant='contained'>Submit</Button>
                                    </div>

                                </Stack>

                            </form>
                        </CardContent>
                    </Card>

                    {
                      !loading && data && data.length > 0 &&
                      <Card>
                          <CardContent>
                              <Print>

                                <InvoiceHeader>
                                    {console.log("Collecitons: ", data)}

                                    <InvoicerBody 
                                      name = {student?.name} 
                                      studentId = {student?.studentId} 
                                      phone={student?.phone} 
                                      classLevel={student?.classLevel?.name}
                                      section={student?.section?.name}
                                    />

                                    <InvoicerTable headings={[]} >
                                    
                                        {
                                          data?.map( (item, idx) => {
                                              return <>
                                                  <div className="tr-title-month">
                                                    {item.monthYear}
                                                  </div>
                                                  <table className='table' key={idx}>
                                                    <thead>
                                                        <tr>
                                                            {/* <th>Trx</th>
                                                            <th>Title</th>
                                                            <th>Paid</th> */}
                                                            <td>
                                                              {/* <div className="row">
                                                                  <div className="col-xs-12 col-sm-4">Trx</div>
                                                                  <div className="col-xs-12 col-sm-4">Title</div>
                                                                  <div className="col-xs-12 col-sm-4">Paid</div>
                                                              </div> */}
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                      {
                                                        item.feeCollections?.map((item, idx) => (

                                                                   
                                                            item.typeOfFees.map((fee, fidx)=>(

                                                                  <tr key={fidx}>
                                                                      <td>
                                                                          <div className="row">
                                                                              <div className="col-xs-12 col-sm-4">{item.tnxId}</div>
                                                                              <div className="col-xs-12 col-sm-4">{fee.title}</div>
                                                                              <div className="col-xs-12 col-sm-4">{item.totalAmount}</div>
                                                                          </div>
                                                                      </td>
                                                                      {/* <td>{item.tnxId}</td>
                                                                      <td>{fee.title}</td>
                                                                      <td>{item.totalAmount}</td> */}                                                                      
                                                                  </tr>
  
                                                            ))
                                                                  
                                                        ))
                                                      }
                                                    </tbody>

                                                  </table>
                                              </>
                                          })
                                        }                                     
                                        
                                    </InvoicerTable>

                                    <InvoicerFooter title={"Total Paid Amount"} totalFee={totalPaidAmount} />
                                    <InvoicerFooter title={"Advance Payment"} totalFee={advancePayment} />
                                    <InvoicerFooter title={"Total Due"} totalFee={totalDue} />
                                    
                                </InvoiceHeader>

                              </Print>
                          </CardContent>
                      </Card>
                    }

                </Stack>
            </div>
        </div>

        {/* get invoice */}
      
    </>
  )
}

export default StdentSingleReport
