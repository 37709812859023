import { Backdrop, Card, CardContent, CircularProgress } from '@mui/material'
import React from 'react'
import useApi from '../../hooks/useApi'
import InvoiceHeader from '../../components/InvoiceHeader'
import InvoicerTable from '../../components/InvoicerTable'
import InvoicerFooter from '../../components/InvoicerFooter'
import { dayjs } from 'dayjs'
import Print from '../../components/Print'

//https://api.ibnhaysam.com
//http://localhost:2020
const url = `https://api.ibnhaysam.com/api/v1/studentFinancial/daily-transactions`

const DailyClassBasedTrx = ({start, end}) => {

    console.log(start, end)

   const { data, isLoading, error } =  useApi(`${url}/${(start)}/${end}`)

  return (
    <>

        <Backdrop open={isLoading}><CircularProgress /></Backdrop>

        <div className="row">
            <div className="col-xs-12 col-sm-8">
                {
                    !isLoading && data &&



                    <Card>
                        <CardContent>

                            <Print>
                                
                                <InvoiceHeader >
                                    <div className="date text-center">{`From ${start} To ${end}`}</div>
                                    <InvoicerTable headings={["Class", "Section", "Amount"]} >
                                        
                                        {data.transactions?.map((item, idx) => (
                                            
                                                <tr key={idx}>
                                                    <td>{item.classLevel}</td>
                                                    <td colSpan={2}>
                                                        <table className='table'>
                                                            <tbody>
                                                                {item.sections?.map((section, sidx) => (
                                                                <tr key={sidx}>
                                                                    <td>{section.section}</td>
                                                                    <td className='total-fee'>{section.totalFee}</td>
                                                                </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>

                                            ))}                                       
                                        
                                    </InvoicerTable>

                                    <InvoicerFooter title={"Total"} totalFee={data.totalFee} />

                                </ InvoiceHeader>

                            </Print>


                        </CardContent>
                    </Card>

                }
            </div>
        </div>
      
    </>
  )
}

export default DailyClassBasedTrx
