import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ContextProvider } from './context/ContextProvider'
import AuthProvider from 'react-auth-kit';
import createStore from 'react-auth-kit/createStore';

const root = ReactDOM.createRoot(document.getElementById('root'));

const store = createStore({
    authName:'_auth',
    authType:'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:',
  });

root.render(
    <ContextProvider>
        <AuthProvider
            store={store}
        >
            <App />
        </AuthProvider>
    </ContextProvider>
);


