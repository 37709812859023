import { Backdrop, Button, Card, CardContent, CircularProgress, Stack, TextField } from '@mui/material'
import { DateField, DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

const Attendance = () => {

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)

  // "fetch_log"
  const formik = useFormik({
    initialValues: {
      operation:"fetch_log",
      auth_user: "ihbm",
      auth_code:"aet7j0bzz0fgu2q3pd4189csm06rhn0",
      start_date: null,
      end_date: null,
      start_time:"06:49:09",
      end_time:"14:59:59",
      access_id:52559874,
    },
    validationSchema: Yup.object({
      start_date: Yup.string().required("Start Date required"),
      end_date: Yup.string().required("End Date required")
    }),
    onSubmit: async (values) => {
      try {
        console.log("Values: ", values)
    
        setLoading(true);
    
        const response = await fetch('https://rumytechnologies.com/rams/json_api', {
          method: 'POST',
          body: JSON.stringify(values)
          // Don't set 'Content-Type', let the browser handle it
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        console.log("Response: ", data);
        setData(data.log)
      } catch (err) {
        console.error("Error: ", err.message);
      } finally {
        setLoading(false);
      }
    },   
    
  });
  

  return (
    <>
    <Backdrop open={loading}><CircularProgress /></Backdrop>
    <div className="row">
      <div className="col-sm-12 col-md-12">
          <Card>
            <CardContent>
                <div className="row">
                  <div className="col-sm-12 col-md-12">
                    <form onSubmit={formik.handleSubmit}>
                        <Stack spacing={2}>

                          <div className="row">
                              <div className="col-sm-4 col-md-4">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer
                                    components={['DatePicker']}
                                  >
                                      <DatePicker
                                        label="Start Date"
                                        name="start_date"
                                        onChange={
                                          (newValue)=>{
                                            formik.setFieldValue( 'start_date', dayjs(newValue).format("YYYY-MM-DD") )
                                          }
                                        }
                                        helperText={formik.errors.start_date}
                                      >

                                      </DatePicker>
                                  </DemoContainer>
                                </LocalizationProvider>
                              </div>
                              <div className="col-sm-4 col-md-4">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer
                                    components={['DatePicker']}
                                  >
                                      <DatePicker
                                        label="End Date"
                                        name="end_date"
                                        onChange={
                                          (newValue)=>{
                                            formik.setFieldValue( 'end_date', dayjs(newValue).format('YYYY-MM-DD') )
                                          }
                                        }
                                        helperText={formik.errors.end_date}
                                      >

                                      </DatePicker>
                                  </DemoContainer>
                                </LocalizationProvider>
                              </div>
                              <div className="col-sm-4 col-md-4"></div>
                          </div>

                          <div className="row btn">
                              <div className="col-sm-12">
                                <Button type="submit" variant='contained'>Submit</Button>
                              </div>
                          </div>

                        </Stack>
                    </form>
                  </div>
                  <div className="col-sm-4 col-md-4"></div>
                  <div className="col-sm-4 col-md-4"></div>
                </div>
                
            </CardContent>
          </Card>
      </div>
    </div>

    <div className="row">
      <div className="col-12">
        <Card>
            <CardContent>
                { data && data.length > 0 &&

                  <DataGrid 
                    columns={[]}
                    rows={[]}
                    initialState={{
                      pagination: {
                          paginationModel: {
                              pageSize: 10,
                          },
                      },
                    }}
                    slots={{
                      toolbar: GridToolbar
                    }}
                    slotProps={{
                      toolbar: {
                        csvOptions: { fileName: "Attendance Report" },
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 }
                      }
                    }}
                    pageSizeOptions={[10, 50, 100]}                    
                    disableRowSelectionOnClick
                    disableColumnMenu
                  />
                
                }
            </CardContent>
        </Card>
      </div>
    </div>
      
    </>
  )
}

export default Attendance
