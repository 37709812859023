import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React from 'react'

const StudentsInClass = ({students}) => {
    const columns = [
        { field: 'studentId', headerName: 'ID', flex: 1 },
        { field: 'studentRoll', headerName: 'Roll', flex: 1,
        renderCell: params => {
            let roll = String(params.value).padStart(3, '0');
            return roll;

        }
        },
        { field: 'name', headerName: 'Student name', flex: 1 },
        { field: 'email', headerName: 'Student Email', flex: 1 },
        { field: 'gender', headerName: 'Gender', flex: 1 },
        { field: 'bloodGroup', headerName: 'Blood Group', flex: 1 },
        { field: 'phone', headerName: 'Phone', flex: 1 },
    ]
  return (
    <>
      <DataGrid 
            autoHeight
            columns={columns}
            rows={students}
            slots={{
                toolbar: GridToolbar
            }}
            slotProps={{
                toolbar: {
                    csvOptions: { fileName: "Student Finance" },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                }
            }}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 10,
                    }
                }
            }}
            // pageSizeOptions={[10, 25, 50, 100]}
            getRowId={row => row && row._id}
      />
    </>
  )
}

export default StudentsInClass
