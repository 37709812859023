import React from 'react'

const InvoicerBody = ({ name, studentId, phone, classLevel, section }) => {
  return (
    <>
        <div className="invoicer-infos">
            <div className="row">
                <div className="col-xs-12 col-sm-6">
                    {/* <p className="invoicer-para-title">Invoice:</p> */}
                    <div className="row">

                        <div className="col-xs-12 col-sm-6">
                            Name:
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            {name}
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            Student Id:
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            { studentId }
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            Phone:
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            { phone }
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            Class:
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            { classLevel }
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            Section:
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            { section }
                        </div>

                    </div>
                </div>
                <div className="col-xs-12 col-sm-4"></div>
            </div>

        </div>
    </>
  )
}

export default InvoicerBody
