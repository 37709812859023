import { Backdrop, Button, Card, CardContent, CircularProgress, Stack, TextField } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import useApi from '../../hooks/useApi'
import { ToastContainer, toast } from 'react-toastify'
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import axios from 'axios'
import { useParams } from 'react-router-dom'

const url = 'https://api.ibnhaysam.com/api/v1/grade/get-grade'

const EditGrade = () => {

    const {id} = useParams()
    const {data, isLoading, error} = useApi(`${url}/${id}`)
    const [loading, setLoading] = useState(false)
    const authHeader = useAuthHeader()

    const formik = useFormik({
        initialValues: {
            minMarks: '',
            maxMarks: '',
            grade: ''
        },
        validationSchema: Yup.object({
            minMarks: Yup.number().required("Minimum Mark is required!"),
            maxMarks: Yup.number().required("Maximum mark is required"),
            grade: Yup.string().required("Grade is required")
        }),
        onSubmit: async (values) => {

            try{
                setLoading(true)

                const headers = {
                    "Authorization": authHeader,
                    "Content-Type": "application/json"
                }

                const response = await axios.put(`https://api.ibnhaysam.com/api/v1/grade/update-grade/${id}`, values, {headers: headers})
                toast.success(response.data.message)
                console.log(response.data.data)
                
            }catch(error){
                console.error(error)
                toast.error(error.response.data.message)
                setLoading(false)
            }finally{
                setLoading(false)
            }
        }
    })

    useEffect(() => {
        if (data && !isLoading) {
            formik.setValues({
                minMarks: data.minMarks,
                maxMarks: data.maxMarks,
                grade: data.grade
            });
        }
    }, [data, isLoading]);
    

  return (
    <>
      <Card>
            <CardContent>

                <Backdrop open={loading}> <CircularProgress /> </Backdrop>
                <ToastContainer />

                <form onSubmit={formik.handleSubmit}>
                    
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <Stack spacing={2}>

                                <TextField 
                                    name="minMarks"
                                    value={formik.values.minMarks}
                                    label="Min Mark"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.minMarks && Boolean(formik.errors.minMarks)}
                                    helperText={formik.touched.minMarks && formik.errors.minMarks}
                                />

                                <TextField 
                                    name="maxMarks"
                                    value={formik.values.maxMarks}
                                    label="Max Mark"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.maxMarks && Boolean(formik.errors.maxMarks)}
                                    helperText={formik.touched.maxMarks && formik.errors.maxMarks}
                                />

                                <TextField 
                                    name="grade"
                                    label="Grade"
                                    value={formik.values.grade}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.grade && Boolean(formik.errors.grade)}
                                    helperText={formik.touched.grade && formik.errors.grade}
                                />

                                <div>
                                    <Button type="submit" variant='contained'>Update</Button>
                                    
                                </div>

                            </Stack>
                        </div>
                    </div>

                </form>


            </CardContent>
      </Card>
    </>
  )
}

export default EditGrade
