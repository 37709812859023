import { Backdrop, Button, Card, CardContent, CircularProgress, Stack } from '@mui/material'
import React, { useState } from 'react'
import SelectClass from '../../components/SelectClass'
import { useFormik } from 'formik'
import axios from 'axios'
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import { ToastContainer, toast } from 'react-toastify'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import InvoiceHeader from '../../components/InvoiceHeader'

const url = 'https://api.ibnhaysam.com/api/v1/studentFinancial/check-due-report-by-class'

const InvoiceToolbar = ({classLevel, totalDue})=>{
    return (
        <>
            <GridToolbar />
            <InvoiceHeader>
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <div className="row">

                            <div className="col-xs-12 col-sm-4">
                                <h2>Class:</h2>
                            </div>
                            <div className="col-xs-12 col-sm-8">
                                <h2>{classLevel}</h2>
                            </div>
                            <div className="col-xs-12 col-sm-4">
                                <h2>Total Dues:</h2>
                            </div>
                            <div className="col-xs-12 col-sm-8">
                                <h2>{totalDue}</h2>
                            </div>

                        </div>
                    </div>
                </div>
            </InvoiceHeader>
        </>
    )
}

const DueReport = () => {

    const authHeader = useAuthHeader()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [totalDue, setTotalDue] = useState(0)
    const [classLevel, setClassLevel] = useState(null)

    const formik = useFormik({
        initialValues: {
            classLevel: ''
        },
        onSubmit: async (values) => {
            try{
                console.log(values)
                setLoading(true)
                const headers = {
                    "Authorization": authHeader,
                    "Content-Type": "application/json"
                }

                const response = await axios.post(url, values, {headers: headers})
                console.log(response.data.data)
                toast.success(response.data.message)
                setData(response.data.data)
                setTotalDue(response.data.totalDue)
                setClassLevel(response.data.classLevel)

            }catch(errors){
                setLoading(false)
                toast.error(errors.message)
                console.error(errors)
            }finally{
                setLoading(false)
            }
        }
    })

    const columns = [
        { 
            field: 'studentRoll', 
            headerName: "Student Roll", 
            flex: 1
        },
        { 
            field: 'name', 
            headerName: "Student Name", 
            flex: 1,
        },
        { 
            field: 'section', 
            headerName: "Student Section", 
            flex: 1,
        },
        { 
            field: 'dues', 
            headerName: "Total Due", 
            flex: 1,
        },
    ]

  return (
    <>

        {
            <ToastContainer />
        }

        <Stack spacing={2}>
            <Card>
                <CardContent>
                    <div className="row">

                        <div className="col-xs-12 col-sm-6">
                            <form onSubmit={formik.handleSubmit}>

                                <Stack spacing={2}>

                                    <SelectClass 
                                        value={formik.values.classLevel} 
                                        touched={formik.touched.classLevel} 
                                        errors={formik.errors.classLevel}
                                        onChange={formik.handleChange}
                                    />

                                    <div>
                                        <Button variant='contained' type="submit">Select</Button>
                                    </div>
                                </Stack>

                            </form>
                        </div>
                    </div>
                </CardContent>
            </Card>
            {
                <>
                    <Backdrop open={loading}><CircularProgress /></Backdrop>

                    {
                        !loading && data && data.length > 0 && 
                            <Card>
                                <CardContent>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12">
                                            <DataGrid 
                                                className="due-report"
                                                rows={data}
                                                columns={columns}
                                                initialState={{
                                                    pagination: {
                                                        paginationModel: {
                                                            pageSize: 50,
                                                        },
                                                    },
                                                }}
                                                slots={{
                                                    toolbar: InvoiceToolbar,
                                                }}
                                                slotProps={{
                                                    toolbar: {
                                                        csvOptions: { fileName: "Due Report" },
                                                        showQuickFilter: true,
                                                        quickFilterProps: { debounceMs: 500 },
                                                        totalDue: totalDue,
                                                        classLevel: classLevel
                                                    }
                                                }}
                                                pageSizeOptions={[50]}
                                                
                                                disableRowSelectionOnClick
                                                disableColumnMenu
                                                getRowId={(row) => row.studentRoll}
                                            />
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                    }
                
                </>
            }
        </Stack>
      
    </>
  )
}

export default DueReport
