import React from 'react'

const InvoicerFooter = ({title, totalFee}) => {
  return (
    <>

        <div className="row" style={{textAlign: "right"}}>
            <div className="col-xs-12 col-sm-4"></div>
            <div className="col-xs-12 col-sm-4">
                <h3>{title}:  </h3>
            </div>
            <div className="col-xs-12 col-sm-4">
                { totalFee }
            </div>
            
        </div>
      
    </>
  )
}

export default InvoicerFooter
