import React, { useEffect, useState } from 'react'
import { Backdrop, Card, CardContent, CircularProgress } from '@mui/material';
import axios from 'axios';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import { toast } from 'react-toastify';

const url = 'https://api.ibnhaysam.com/api/v1/studentFinancial/get-fee-collection-by-student-id';

const ViewDueForSingleStudent = ({studentIdForDue}) => {

    //const {data, isLoading, error } = useApi(`${url}/${id?.studentIdForDue}`)
    const authHeader = useAuthHeader()
    const [loading, setLoading ] = useState(false)
    const [data, setData] = useState([])

    useEffect( ()=>{
      
      const fetch = async (id) => {
          try{
            setLoading(true)
            const headers = {
              "Authorization": authHeader,
              "Content-Type": "application/json"
            };

            console.log(id)
            const response = await axios.post(url, {studentId: id}, {headers: headers})
            setData(response.data)
            toast.success(response.data.message)
            console.log("Response Data from view due: ", response.data)

          }catch(error){
              setLoading(false)
              console.error(error)
              toast.error(error.message)
          }finally{
              setLoading(false)
          }
      }

      if(studentIdForDue){
        fetch(studentIdForDue)
      }

      return ()=>{}

    }, [studentIdForDue])
    
  return (
    <>  

      {
        (data.status === "not-registered") ? "Not Registered" : ""
      }

      {
        !loading && data && data.status !== "not-registered" &&

                <div className="row">
                    <div className="col-xs-12 col-sm-12">
                        <div className="row">
                            <div className="col-xs-12 col-sm-8">
                                <h2 className="name">{data?.student?.name}</h2>
                                <p>Class: <span>{data?.student?.classLevel?.name}</span></p>
                                <p>Section: <span>{data?.student?.section?.name}</span></p>
                                <p>Student Id: <span>{data?.student?.studentId}</span></p>
                            </div>
                            <div className="col-xs-12 col-sm-4">
                                <div className="view-due-comp-profile-image-box">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="line"></div>
                    <div className="col-xs-12 col-sm-6">
                        <h1 className="view-due-total-due">{data?.totalDue} BDT</h1>
                        <p>Due</p>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <h1 className="view-due-total-due">{data?.advancePayment} BDT</h1>
                        <p>Advance</p>
                    </div>
                </div>

      }

    </>
  )
}

export default ViewDueForSingleStudent
